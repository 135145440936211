import React from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';
import {T} from '@deity-io/falcon-i18n';
import './BlogItem.scss'
import productionConfiguration from '../../../config/default.json';
import readConfig from '../../utils/readClientConfiguration';

const dateFormat = readConfig(productionConfiguration, 'dateFormat');

const BlogItem = ({title, description, image_src, order, url, creation_date}) => {
    const date = moment(creation_date).format(dateFormat);
    return <div className="blog-item__container">
        <Link to={`/blog/${url}`}><img className="blog-item__img" src={image_src}/></Link>
        <Link to={`/blog/${url}`}><h5 className="blog-item__title">{title}</h5></Link>
        <div className="blog-item__date-div">
            <i><span className="blog-item__date"><T id="blog.published"/> {date}</span></i>
        </div>
        <div className="blog-item__description" dangerouslySetInnerHTML={{
            __html: description
        }}  />
        <Link to={`/blog/${url}`} className="blog-item__button"><T id="blog.showMore"/></Link>
    </div>
}

export default BlogItem;