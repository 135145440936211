import React from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import productionConfiguration from '../../../config/default.json';
import readConfig from '../../utils/readClientConfiguration';
import './NewestBlogItem.scss';

const dateFormat = readConfig(productionConfiguration, 'dateFormat');

const NewestBlogItem = ({title, url, creation_date, image_src, order, blog}) => {
    const date = moment(creation_date).format(dateFormat);
    return <div className="new-blog-item__container">
        <Link to={blog ? `/blog/${url}` : url}><img className="new-blog-item__img" src={image_src}/></Link>
        <Link to={blog ? `/blog/${url}` : url}><h5 className="new-blog-item__title">{title}</h5></Link>
        <span className="new-blog-item__date">{date}</span>
    </div>
}

export default NewestBlogItem;