import React, {Fragment} from 'react';
import {useQuery} from "@apollo/react-hooks";
import {useParams, Link} from 'react-router-dom'
import {T} from "@deity-io/falcon-i18n";
import {BLOG_POST, LATEST_BLOG_POSTS, BLOG_TAGS} from "../queriesBlog";
import NewestBlogItem from "../NewestBlogItem";
import { Loader } from '@deity-io/falcon-ecommerce-uikit';

import '../Blog.scss';
import './BlogDetails.scss';
import '../../../components/Category/Breadcrumbs/Breadcrumbs.scss';
import Helmet from 'react-helmet';

const BlogDetails = () => {
    const params = useParams();

    const {data, loading} = useQuery(BLOG_POST, {
        variables: { identifier: params.blogId },
        fetchPolicy: "cache-first"
    })
    const {data: latestPostsData, loading: load} = useQuery(LATEST_BLOG_POSTS, {
        fetchPolicy: "cache-first"
    });
    const {data: blogTagsData, loading: lo} = useQuery(BLOG_TAGS, {
        fetchPolicy: "cache-first"
    });

    return <Fragment>
        {data && data.blogPost && (
            <Helmet>
                <title>{data.blogPost.title}</title>
                {data.blogPost.og_title && <meta property="og:title" content={data.blogPost.og_title} />}
                {data.blogPost.og_description && <meta property="og:description" content={data.blogPost.og_description} />}
                <meta property="og:type" content={data.blogPost.og_type || 'article'} />
                {data.blogPost.og_image && <meta property="og:image" content={data.blogPost.og_image} />}
            </Helmet>
        )}
        <div className="blog">
            <div className="blog__breadcrumbs">
                <div className="breadcrumbs">
                    <Link className="breadcrumbs__link" to="/">
                        Naslovna
                    </Link>
                    <Link className="breadcrumbs__link" to="/blog"><T id="blog.title" /></Link>
                    <span>{!loading && data.blogPost.title}</span>
                </div>
            </div>

            {loading && <Loader />}

            <main className="blog__main-content">

                <div className="blog__column">

                    <div className="blog-details__column-main">
                        <div className="blog-details">

                            {!loading &&
                              <>
                                <h1 className='blog-details__title'>{data.blogPost.title}</h1>
                                <span className="blog-details__date"><T id="blog.published"/> {data.blogPost.update_time}</span>
                                  <div>
                                    <span className="blog-details__category">
                                        <T id="blog.category"/>
                                        <Link
                                          className="blog-details__category-link"
                                          to={"/blog"}>
                                            {data.blogPost.__typename}
                                        </Link>
                                    </span>
                                  </div>
                              </>
                            }

                            {!loading && data.blogPost.tags &&
                            data.blogPost.tags.length > 0 &&
                                <div className="blog-details__tags">
                                    <span className="blog-details__tags-text"><T id="blog.tag"/></span>
                                    {data.blogPost.tags.map(({title, identifier}) => (
                                        <span key={title}>
                                            <Link className="blog-details__tags-link" to={`/blog/tag/${identifier}`}>{title}</Link>, </span>
                                    ))}
                                </div>
                            }

                            {!loading &&
                                <div className='blog-details__content'>
                                    <div dangerouslySetInnerHTML={{ __html: data.blogPost.content }} />
                                </div>
                            }

                            {!loading && data.blogPost.related_posts &&
                                data.blogPost.related_posts.length > 0 &&
                            <div className="blog-details__related-posts">
                                <h3 className="blog-details__related-posts__title"><T id="blog.related"/></h3>
                                <hr/>

                                {!loading && data.blogPost.related_posts.map(({title, identifier}) => (
                                    <Link className="blog-details__related-posts__link" key={title}
                                          to={identifier}>{title}</Link>
                                ))}
                            </div>}

                        </div>
                    </div>

                    <div className="blog__column-sidebar">
                        <h3><T id="blog.newestPost"/></h3>
                        {!load && latestPostsData.latestBlogPosts.map(({title, identifier, first_image, update_time, order}) => (
                            <NewestBlogItem
                                title={title}
                                url={identifier}
                                image_src={first_image}
                                creation_date={update_time}
                                order={order}
                            />
                        ))}

                        <div className="blog__column-sidebar__tags">
                            <h2><T id="blog.tags"/></h2>
                            {!lo && blogTagsData.blogTags.map(({identifier, title}) => (
                                <Link to={`/blog/tag/${identifier}`}>#{title}</Link>
                                ))}
                        </div>
                    </div>

                </div>

            </main>
        </div>
    </Fragment>

}

export default BlogDetails;