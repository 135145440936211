import React, {Fragment, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {Link, useParams} from "react-router-dom";
import {T} from '@deity-io/falcon-i18n';
import BlogItem from './BlogItem';
import NewestBlogItem from "./NewestBlogItem";
import {BLOG_LIST, LATEST_BLOG_POSTS} from "./queriesBlog";
import ReactPaginate from "react-paginate";
import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import './Blog.scss';
import '../../components/Category/Breadcrumbs/Breadcrumbs.scss';

const Blog = () => {

    const [posts, setPosts] = useState([])
    const [latestPosts, setLatestPosts] = useState([])
    const [tags, setTags] = useState([])

    const params = useParams()
    const [itemOffset, setItemOffset] = useState(0);
    const {data: blogData, loading} = useQuery(BLOG_LIST, {
        variables: {
            page: itemOffset,
            pageSize: 9,
            tag: params.tagId ? params.tagId : null
        },
        ssr: true,
        onCompleted: (data) => {
            setPosts(data.blogList.posts)
            setTags(data.blogList.tags)
        }
    });
    const {data, loading: load} = useQuery(LATEST_BLOG_POSTS, {
        onCompleted: (data) => {
            setLatestPosts(data.latestBlogPosts)
        }
    });
    const handlePageClick = (event) => {
        const newOffset = event.selected + 1;
        setItemOffset(newOffset);
    };
    return <Fragment>
        <div className="blog">
            <div className="blog__breadcrumbs">
                <div className="breadcrumbs">
                    <Link className="breadcrumbs__link" to="/">
                        Naslovna
                    </Link>
                    {!params.tagId ? <span>Blog</span> :
                        <Link className="breadcrumbs__link" to="/blog"><T id="blog.title"/></Link>}
                    {params.tagId && <span>{params.tagId}</span>}
                </div>
            </div>

            {loading && <Loader /> }

            <main className="blog__main-content">
                <div className="blog__page-title">
                    <h1><T id="blog.title"/></h1>
                </div>

                <div className="blog__column">

                    <div className="blog__column-main">
                        {posts.map(({post_id, title, identifier, first_image, short_content, update_time, order}) => (
                            <BlogItem
                                key={post_id}
                                title={title}
                                url={identifier}
                                image_src={first_image}
                                description={short_content}
                                creation_date={update_time}
                                order={order}
                            />
                        ))}
                    </div>

                    <div className="blog__column-sidebar">
                        <h3><T id="blog.newestPost"/></h3>
                        {latestPosts.map(({post_id, title, identifier, first_image, update_time, order}) => (
                            <NewestBlogItem
                                key={post_id}
                                title={title}
                                url={identifier}
                                image_src={first_image}
                                creation_date={update_time}
                                order={order}
                                blog
                            />
                        ))}

                        <div className="blog__column-sidebar__tags">
                            <h2><T id="blog.tags"/></h2>
                            {tags.map(({identifier, title}) => (
                                <Link key={identifier} to={`/blog/tag/${identifier}`}>#{title}</Link>
                            ))}
                        </div>
                    </div>

                </div>

                {blogData && blogData.blogList && blogData.blogList.last_page > 1 &&
                    <div className='blog__pagination'>
                        <ReactPaginate
                            previousLabel={<div className='pagination__arrow_prev'/>}
                            nextLabel={<div className='pagination__arrow_next'/>}
                            forcePage={0}
                            breakClassName={'pagination__page'}
                            pageCount={blogData && blogData.blogList.last_page}
                            marginPagesDisplayed={-1}
                            pageRangeDisplayed={4}
                            onPageChange={handlePageClick}
                        disabledClassName={'disabled'}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        previousClassName={'pagination__arrow'}
                        previousLinkClassName={'pagination__arrow-link'}
                        nextClassName={'pagination__arrow'}
                        nextLinkClassName={'pagination__arrow-link'}
                        pageClassName={'pagination__page'}
                        activeClassName={'active-page'}
                        activeLinkClassName={'active-link'}
                        />
                    </div>
                }

            </main>
        </div>
    </Fragment>
};

export default React.memo(Blog);
