import gql from "graphql-tag";

export const BLOG_LIST = gql`
    query blogList($page: Int, $pageSize: Int,$tag: String) {
        blogList(page: $page, pageSize:$pageSize,tag: $tag) {
            url
            posts {
                post_id
                identifier
                title
                short_content
                update_time
                first_image
                featured_img
            }
            last_page
            tags{
                title
                identifier
            }
        }
    }
`

export const LATEST_BLOG_POSTS = gql`
    query latestBlogPosts($pageSize: Int) {
        latestBlogPosts(pageSize: $pageSize) {
            post_id
            first_image
            title
            update_time
            identifier
        }
    }
`

export const BLOG_TAGS = gql`
    query {
        blogTags {
            title
            identifier
        }
    }
`

export const BLOG_POST = gql`
    query blogPost($identifier: String!) {
        blogPost(identifier: $identifier) {
            title
            update_time
            tags {
                title
                identifier
            }
            related_posts{
                title
                identifier
            }
            content
            og_img
            og_type
            og_title
            og_description
        }
    }
`